import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  buyUrl : 'https://1.envato.market/6NV1b',
  SCARF_ANALYTICS : false,
  adminRoot: '/app',


  socketUrl: 'http://34.235.122.30:8000/',
  apiUrl: 'http://34.235.122.30:8000/v3',



  // socketUrl: 'http://18.140.103.7:8000/',
  // apiUrl: 'http://18.140.103.7:8000/v3',
  // socketUrl: 'https://api.deliver.sg/',
  // apiUrl: 'https://api.deliver.sg/v3',
  // socketUrl: 'http://localhost:8000/',
  // apiUrl: 'http://localhost:8000/v3',
  // apiUrl: 'http://192.168.0.73:8000/v3',
  // imageUrl: 'http://192.168.0.160:8000/',
  // apiUrl: 'https://edelivery.appemporio.net/v3',
  // imageUrl: 'http://localhost:8000/',
  // imageUrl: 'https://edelivery.appemporio.net/',
  // imageUrl: 'https://apiedeliverynew.appemporio.net/',
  // apiUrl: 'https://apiedeliverynew.appemporio.net/v3',
  // imageUrl: 'https://edelivery.appemporio.net/',
  // apiUrl: 'https://edelivery.appemporio.net',
  // imageUrl: 'https://apiedeliverydeveloper.appemporio.net/',
  // apiUrl: 'https://apiedeliverydeveloper.appemporio.net',
  imageUrl: 'https://deliversgbucket.s3.ap-southeast-1.amazonaws.com/',
  
  //apiUrl: 'http://127.0.0.1:8000/v3',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,  
  defaultRole: UserRole.Admin,
  firebase: { 
    apiKey: "AIzaSyBViKhoMt_4-rQ92byigbEanQEAmVJS1aA",
    authDomain: "deliversg-1570451296267.firebaseapp.com",
    databaseURL: "https://deliversg-1570451296267.firebaseio.com",
    projectId: "deliversg-1570451296267",
    storageBucket: "deliversg-1570451296267.appspot.com",
    messagingSenderId: "117663095692",
    appId: "1:117663095692:web:9533a4ca4cd6789be154a9",
    measurementId: "G-42KJX4G47W"
  }
};
