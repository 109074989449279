import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';
import { AuthService,Store } from '../shared/auth.service';

export interface CategoryModel {
  _id:string,
  name:any,
  sequence_number:number,
  product_ids:any
}

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private store:Store;

  constructor(private helper: Helper,private _auth:AuthService) {
    this.store = this._auth.getStore();
    this._auth.loginSession.subscribe((data)=>{
      this.store = data
    })
  }

  fetch(){
    var json = {
        server_token: this.store.servertoken,
        store_id: this.store._id
    }
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_DATA, json)
  }

  update(updateStoreForm){
    var json = {
        ...updateStoreForm,
        server_token: this.store.servertoken,
        store_id: this.store._id
    }
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE, json)
  }

  get_languages(){
    return this.helper.http_get_method_requester(this.helper.POST_METHOD.LIST_LANGUAGE, {})
    // .then(res_data => {
    //   console.log(res_data);
    //   if(res_data.success) {
    //     this._profileChanges.next({})
    //   }
    // })
  }

  list_reviews(start_date=null,end_date=null,page = 1,perPage = 10,query = {}){
    let json = {
      start_date,
      end_date,
      page,
      perPage,
      query  
    }
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LIST_REVIEWS, json)

  }



}
