import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { getThemeLang, setThemeLang } from 'src/app/utils/util';
import { ProfileService } from '../services/profile.service';
import { Helper } from './helper';

const languageKey = '__lang';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  isSingleLang = false;
  renderer: Renderer2;
  defaultLanguage = getThemeLang();
  supportedLanguages: Language[] = [];
  private languages = new BehaviorSubject<any>(null);
  languagesObservable = this.languages.asObservable();

  constructor(
    private translate: TranslateService,
    private _profileService:ProfileService,
    private rendererFactory: RendererFactory2,
    private _helper: Helper
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  configLanguages(){ 
    return new Promise((resolve,rejects)=>{
      this._profileService.get_languages().then(res_data=>{
        if(res_data.success){
          this._helper.is_use_captcha = res_data.is_use_captcha
          res_data['lang'].forEach(_lang => {            
              var lang_direction = _lang.is_lang_rtl === true ? 'rtl' : 'ltr'
              this.supportedLanguages.push({ code: _lang.code, direction: lang_direction, label: _lang.name, shorthand: _lang.code })            
          }); 
          setTimeout(() => {
            this.languages.next({})
          })
          resolve(true);
        }else{
          this.supportedLanguages.push({ code: 'en', direction: 'ltr', label: 'English', shorthand: 'en' })
          this.languages.next({})
          resolve(true);
        }
      })
    })
  }

  init(): void {
    // console.log(this.defaultLanguage);
    
    this.configLanguages().then(()=>{
      this.supportedLanguages.forEach(_lang=>{
        try{
          const data = require(`../../assets/i18n/${_lang.code}.json`)
          this.translate.setTranslation(_lang.code, data);
        }catch(err){
          const data = require('../../assets/i18n/en.json')
          this.translate.setTranslation(_lang.code, data);
        }
      })
    })
    const data = require('../../assets/i18n/en.json')
    this.translate.setTranslation('en', data);
    this.translate.setDefaultLang(this.defaultLanguage);
    this.translate.use(this.defaultLanguage);

      this.renderer.removeClass(document.body, 'ltr');
      this.renderer.removeClass(document.body, 'rtl');    
      if(this.translate.currentLang === 'ar'){
        this.renderer.addClass(document.body, 'rtl');
      }else{
        this.renderer.addClass(document.body, 'ltr');
      }
    // if (this.isSingleLang) {
    //   this.translate.use(this.defaultLanguage);
    // } else {
    //   this.language = '';
    // }
  }

  checkForDirectionChange(): void {
    console.log(this.direction);
    console.log(this.languageShorthand);
    
    this.renderer.removeClass(document.body, 'ltr');
    this.renderer.removeClass(document.body, 'rtl');
    this.renderer.addClass(document.body, this.direction);
    this.renderer.setAttribute(document.documentElement,'direction',this.direction);
  }

  set language(lang: string) {
    
    let language = lang || getThemeLang();
    console.log(language);
    const isSupportedLanguage = this.supportedLanguages.map((item) => item.code).includes(language);
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }
    setThemeLang(language);
    this.translate.use(language);
    this.checkForDirectionChange();
    // let language = lang || getThemeLang();
    // const isSupportedLanguage = this.supportedLanguages.map((item) => item.code).includes(language);
    // if (!isSupportedLanguage) {
    //   language = this.defaultLanguage;
    // }

    // if (lang !== '' && this.supportedLanguages.map((item) => item.code).includes(lang) && this.direction !== this.supportedLanguages.find((item) => item.code === lang).direction) {
    //   setThemeLang(lang);
    //   window.location.reload();
    // } else {
    //   this.translate.use(language);
    // }
    // this.checkForDirectionChange();
    // setThemeLang(language);
  }

  get language(): string {
    return this.translate.currentLang;
  }

  get languageShorthand(): string {
      let currentLang = this.supportedLanguages.find(item => item.code === this.translate.currentLang);
      return currentLang ? currentLang.shorthand : 'en';
  }

  get direction(): string {
    let currentDirection = this.supportedLanguages.find(item => item.code === this.translate.currentLang);
    return currentDirection ? currentDirection.direction : 'ltr';
  }

  get languageLabel(): string {
    return this.supportedLanguages.find(item => item.code === this.translate.currentLang).label;
  }
  
  get selectedlanguageIndex():number {
    return this.supportedLanguages.findIndex(item => item.code === this.translate.currentLang);
  }

}

export class Language {
  code: string;
  direction: string;
  label: string;
  shorthand: string;
}
